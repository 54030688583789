<template>
  <div class="page-wrap">
    <div v-if="!showDel">
      <div class="top-card">
        <div class="input-group flex">
          <div class="wrap flex">
            <div class="label">任务名称：</div>
            <div class="r-find">
              <Input v-model="storePrams.title" placeholder="请输入任务名称" style="width: 250px"/>
            </div>
          </div>
          <div class="wrap flex">
            <div class="label">任务有效期：</div>
            <div class="r-find">
              <DatePicker @on-change="changeTime" type="daterange" placement="bottom-end" placeholder="请选择任务有效期"
                          style="width: 250px"></DatePicker>
            </div>
          </div>
          <div class="r-btn flex">
            <div class="s-btn flex-c" @click="getList">搜索</div>
          </div>
        </div>
      </div>
      <div class="table-card">
        <Table :columns="columns" :data="data">
          <template slot-scope="{ row }" slot="operation">
            <div class="flex-c">
              <div class="table-btn del" @click="agree(row)">详情</div>
              <div class="table-btn danger" @click="clearDel(row.id)">删除</div>
            </div>
          </template>
          <template slot-scope="{ row }" slot="allTime">
            <div>{{ row.start_time }} 至 {{ row.end_time }}</div>
          </template>
          <template slot-scope="{ row }" slot="allPerson">
            <div>{{ row.completed_num }}/{{ row.support_num }}</div>
          </template>
          <template slot-scope="{row}" slot="showBtn">
            <div class="flex-c">
              <div class="table-btn del" @click="seePic(row)">查看</div>
            </div>
          </template>
        </Table>
        <Page :total="storePrams.total"
              :current="storePrams.page"
              style="text-align:right;margin-top: 20px"
              show-elevator
              show-total
              @on-change="pageChange"
              :page-size="storePrams.limit"/>
      </div>
    </div>
    <div v-if="seeCard">
      <div class="pop"></div>
      <div class="pop-card fixed flex-c">
        <div class="l-pop-del">
          <div class="pop-pic">
            <img :src="leftImg"/>
          </div>
          <div class="pop-word">参与扫码签署</div>
        </div>
        <div class="r-pop-del">
          <div class="pop-pic">
            <img :src="rightImg"/>
          </div>
          <div class="pop-word">其他人扫码签署</div>
        </div>
        <div class="pos-btn flex-c" @click="seeCard = false">关闭</div>
      </div>
    </div>
    <div v-if="seeTable">
      <div class="pop"></div>
      <div class="pop-table fixed">
        <div class="search-group flex">
          <div class="wrap flex">
            <div class="label">任务名称：</div>
            <div class="r-find">
              <Input v-model="elsePrams.title" placeholder="请输入任务名称" style="width: 240px"/>
            </div>
          </div>
          <div class="r-btn flex">
            <div class="s-btn flex-c" @click="getDetail">搜索</div>
          </div>
        </div>
        <Table :columns="columnTable" :data="dataTable">
          <template slot-scope="{}" slot="operation">
            <div class="table-link">查看</div>
          </template>
          <template slot-scope="{row}" slot="name">
            <div class="table-link">{{ row.part.name }}</div>
          </template>
          <template slot-scope="{row}" slot="phone">
            <div class="table-link">{{ row.part.phone }}</div>
          </template>
        </Table>
        <Page :total="elsePrams.total"
              :current="elsePrams.page"
              style="text-align:right;margin-top: 20px"
              show-elevator
              show-total
              @on-change="pageChanges"
              :page-size="elsePrams.limit"/>
        <div class="close-icon" @click="seeTable = false">
          <Icon type="md-close" size="30"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {batchList, batchDetails , delBatch} from '../../api/interFace'
export default {
  name: "contract",
  data() {
    return {
      fromVal: {
        contract_name: '',
        start_time: '',
        end_time: '',
      },
      columns: [
        {
          title: '#',
          align: 'center',
          width: 120,
          key: 'id'
        },
        {
          title: '任务名称',
          align: 'center',
          key: 'task_name'
        },
        {
          title: '合同起止日期',
          align: 'center',
          slot: 'allTime',
        },
        {
          title: '二维码',
          key: 'address',
          align: 'center',
          width: 120,
          slot: 'showBtn'
        },
        {
          title: '参与人数',
          slot: 'allPerson',
          align: 'center'
        },
        {
          title: '创建时间',
          align: 'center',
          key: 'created_at'
        },
        {
          title: '操作',
          key: 'action',
          width: 210,
          align: 'center',
          slot: 'operation'
        }
      ],
      data: [],
      columnTable: [
        {
          title: '合同ID',
          align: 'center',
          width: 120,
          key: 'id'
        },
        {
          title: '签署者',
          align: 'center',
          slot: 'name'
        },
        {
          title: '手机号',
          align: 'center',
          slot: 'phone',
        },
        {
          title: '签署详情',
          align: 'center',
          width: 120,
          key: 'sign_time'
        },
        {
          title: '合同详情',
          key: 'action',
          align: 'center',
          slot: 'operation'
        }
      ],
      dataTable: [],
      showDel: false,
      seeCard: false,
      seeTable: false,
      leftImg:'',
      rightImg:'',
      storePrams: {
        limit: 10,
        page: 1,
        total: 0,
        start_time: '',
        end_time: '',
        title: ''
      },
      elsePrams: {
        limit: 10,
        page: 1,
        total: 0,
        title: '',
        batch_id: '',
      },
    }
  },
  created() {
    this.getList()
  },
  mounted() {

  },
  methods: {
    getList() {
      batchList(this.storePrams).then(res => {
        this.data = res.data.data || []
        this.storePrams.total = res.data.total
      }).catch(res => {
        console.log(res)
      })
    },
    pageChange(index) {
      this.storePrams.page = index
      this.getList()
    },
    agree(e) {
      this.elsePrams.batch_id = e.id
      this.searchMore()
    },
    getDetail(){
      this.searchMore()
    },
    searchMore(){
      batchDetails(this.elsePrams).then(res => {
        this.seeTable = true
        this.dataTable = res.data.data || []
        this.elsePrams.total = res.data.total
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    changeTime(e) {
      this.storePrams.start_time = e[0]
      this.storePrams.end_time = e[1]
    },
    seePic(row){
      this.seeCard = true
      this.leftImg=row.firstparty_code_url
      this.rightImg=row.partyb_code_url
      console.log(row)
    },
    clearDel(id) {
      this.$Modal.confirm({
        title: '删除提示',
        content: '请问您是否确认删除此条签署任务，删除后无法恢复!!!',
        onOk: () => {
          delBatch({batch_id:id}).then(res=>{
            this.$Message.success(res.msg)
            this.getList()
          }).catch(res=>{
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    pageChanges(index) {
      this.elsePrams.page = index
      this.searchMore()
    },
  }
}
</script>

<style scoped lang="scss">
.page-wrap {
  .top-card {
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px 20px;
    box-sizing: border-box;

    .input-group {
      .wrap {
        margin-right: 25px;
      }

      .label {
        font-size: 14px;
        color: #545F78;

        .iconfont {
          color: #4877E8;
          font-size: 18px;
        }
      }

      .r-btn {
        .s-btn {
          width: 78px;
          height: 30px;
          background: #4877E8;
          border-radius: 15px;
          font-size: 12px;
          font-weight: bold;
          color: #FFFFFF;
          cursor: pointer;
          margin-right: 20px;

          &:hover {
            opacity: .4;
          }
        }

        .d-btn {
          width: 78px;
          height: 30px;
          background: #F77F54;
          border-radius: 15px;
          font-size: 12px;
          font-weight: bold;
          color: #FFFFFF;
          cursor: pointer;

          &:hover {
            opacity: .4;
          }
        }
      }
    }
  }

  .table-card {
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px 20px;
    box-sizing: border-box;
    margin-top: 20px;
  }

  .pop-card {
    width: 712px;
    height: 420px;
    background: #FFFFFF;
    border-radius: 5px;
    z-index: 999;

    .l-pop-del, .r-pop-del {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    .pop-pic {
      width: 180px;
      height: 180px;
      border-radius: 6px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }

    .pop-word {
      font-size: 16px;
      color: #191A1F;
      margin-top: 20px;
    }

    .pos-btn {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30px;
      width: 88px;
      height: 34px;
      background: #FFFFFF;
      font-weight: bold;
      border: 1px solid #DBDEE6;
      border-radius: 6px;
      cursor: pointer;
      color: #191A1F;

      &:hover {
        box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.1);
      }
    }

  }

  .pop-table {
    width: 900px;
    //height: 420px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    z-index: 999;

    .search-group {
      margin-bottom: 20px;

      .wrap {
        .label {
          font-size: 14px;
          color: #545F78;
        }
      }

      .s-btn {
        width: 78px;
        height: 30px;
        background: #4877E8;
        border-radius: 15px;
        font-size: 12px;
        font-weight: bold;
        color: #FFFFFF;
        cursor: pointer;
        margin-right: 20px;
        margin-left: 20px;

        &:hover {
          opacity: .4;
        }
      }
    }

    .table-link {
      font-size: 15px;
      font-weight: bold;
      color: #4877E8;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }

    .close-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

  }
}

</style>
